import React, { useEffect, useRef, useState } from "react";
import type { CommunityUser } from "../../api/community";
import { useAlertContext } from "../../components/alert/AlertContext";
import { useParams } from "react-router-dom";
import type { User } from "../../api/user";
import avatar from '../../avatar.jpg'
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios from "axios";

const roles = ['创建人', '族长', '管理员', '普通用户'];

export default function UserManagement() {
    const { id } = useParams();
    const community_id = Number(id);
    const { warn } = useAlertContext();
    const [communityUsers, setCommunityUsers] = useState<CommunityUser[]>([]);
    const fetchCommunityUsers = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/users`);
            setCommunityUsers(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`查询失败 ${e.response?.data}`);
            }
        }
    }
    useEffect(() => {
        fetchCommunityUsers();
    }, [id]);

    return (
        <div className="space-y-4">
            <SearchUser fetchCommunityUsers={fetchCommunityUsers} />
            <div className="grid grid-cols-4 gap-4">
                {communityUsers.map(user => (
                    <UserProfileCard key={user.user_id}
                        user={user}
                        fetchCommunityUsers={fetchCommunityUsers} />
                ))}
            </div>
        </div>
    );
}

function UserProfileCard({ user, fetchCommunityUsers }: { user: CommunityUser, fetchCommunityUsers: () => Promise<void> }) {
    const { success, error } = useAlertContext();
    const menu = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState({
        removing: false,
        changing: false,
    });
    const [menuHidden, setMenuHidden] = useState('hidden');

    const openMenu = () => {
        setMenuHidden('');
    }

    useEffect(() => {
        if (!menuHidden) {
            menu.current?.focus();
        }
    }, [menuHidden]);

    const closeMenu = (event: React.FocusEvent<HTMLDivElement>) => {
        const nextFocusedElement = event.relatedTarget;
        if (nextFocusedElement && menu.current && menu.current.contains(nextFocusedElement as Node)) {
            // The next focused element is inside the div, ignore the blur event
            menu.current.focus();
            return;
        }
        setMenuHidden('hidden');
    }

    const removeUser = async () => {
        setLoading({ ...loading, removing: true });
        try {
            await api.delete(`/api/community/user`, {
                data: {
                    user_id: user.user_id,
                    community_id: user.community_id,
                }
            });
            success('移除成功');
            await fetchCommunityUsers();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`移除失败 ${message}`);
                setLoading({ ...loading, removing: false });
            }
        }
    };
    const changeRole = async (role: number) => {
        setMenuHidden('hidden');
        setLoading({ ...loading, changing: true });
        try {
            await api.put(`/api/community/user`, {
                user_id: user.user_id,
                community_id: user.community_id,
                role: role,
            });
            success('修改成功');;
            await fetchCommunityUsers();
            menu.current?.blur();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`修改失败 ${message}`);
            }
        }
        setLoading({ ...loading, changing: false });

    }
    return (
        <div className="max-w-sm bg-white border border-gray-200 rounded">
            <div className="flex justify-end px-4 pt-4">
                <button className="inline-block text-gray-500 rounded text-sm p-1.5"
                    type="button"
                    onClick={openMenu}>
                    <span className="sr-only">Open dropdown</span>
                    {loading.changing ? <svg className="w-5 h-5 animate-spin" aria-hidden="true" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg> : <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>}
                </button>
                <div className={`absolute z-10 ${menuHidden} text-base list-none bg-white divide-y divide-gray-100 rounded shadow w-44`}
                    ref={menu}
                    tabIndex={0}
                    onBlur={closeMenu}>
                    <ul className="py-2" aria-labelledby="dropdownButton">
                        {roles.map((role, index) => (
                            <li>
                                <button type="button"
                                    className="w-full text-black hover:bg-gray-100 px-3 py-2 text-sm text-center"
                                    disabled={index === user.role}
                                    onClick={() => changeRole(index)}>
                                    {role}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="flex flex-col items-center pb-10">
                <img className="w-24 h-24 mb-3 rounded-full" src={avatar} alt="Avatar" />
                <h5 className="mb-1 text-xl font-medium text-gray-900">{user.name}</h5>
                <span className="text-sm text-gray-500">{roles[user.role!]}</span>
                <LoadingButton
                    className="text-white text-md w-2/3 bg-red-700 rounded px-4 py-2 mt-4 hover:bg-red-800"
                    loading={loading.removing}
                    text="从族谱移除"
                    onClick={async () => await removeUser()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </LoadingButton>
            </div>
        </div>
    );
}

function SearchUser({ fetchCommunityUsers }: { fetchCommunityUsers: () => Promise<void> }) {
    const { id } = useParams();
    const community_id = Number(id);
    const { success, warn, error } = useAlertContext();
    const [user, setUser] = useState<User>();
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState({
        searching: false,
        adding: false,
    });

    const searchUser = async () => {
        if (!mobile) {
            warn('手机号必填');
            return;
        }
        setLoading({ ...loading, searching: true });
        setUser(undefined);
        try {
            const response = await api.get(`/api/community/${community_id}/user?mobile=${mobile}`);
            setUser({
                user_id: response.data.user_id,
                nickname: response.data.name,
            });
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`搜索失败 ${message}`);
            }
        }
        setLoading({ ...loading, searching: false });
    }

    const addUser = async () => {
        if (!user) {
            warn('未指定用户');
            return;
        }
        setLoading({ ...loading, adding: true });
        try {
            const response = await api.post('/api/community/user', {
                community_id: community_id,
                user_id: user.user_id,
                role: 3,
            });
            success(`添加成功 ${response.data}`);
            setUser(undefined);
            setMobile('');
            await fetchCommunityUsers();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`添加失败 ${message}`);
            }
        }
        setLoading({ ...loading, adding: false });
    }
    return (
        <div className="space-x-4 flex justify-start">
            <div className="flex items-center max-w-sm">
                <div className="relative w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                        </svg>
                    </div>
                    <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-black focus:border-black block w-full ps-10 p-2.5"
                        placeholder="使用手机号搜索"
                        type="text"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)} />
                </div>
                <LoadingButton className="p-2.5 ms-2 text-sm font-medium text-white bg-black rounded border border-black hover:bg-gray-800"
                    loading={loading.searching}
                    text=""
                    onClick={searchUser}>
                    <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </LoadingButton>
            </div>
            {user && <span className="inline-flex items-center px-4 py-1 me-2 text-lg font-medium text-yellow-800 bg-yellow-100 rounded">
                {user.nickname}
                <LoadingButton className="p-1 ms-2 text-sm text-yellow-400 bg-transparent rounded-sm hover:text-yellow-900"
                    loading={loading.adding}
                    text=''
                    onClick={async () => await addUser()}>
                    <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>
            </span>}
        </div>
    );
}