import { useState } from "react";
import { useAlertContext } from "../../components/alert/AlertContext";
import type { Community } from "../../api/community";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios from "axios";

export default function CreateCommunity() {
    document.title = '创建新族谱';
    const { fetchCommunities } = useOutletContext<{ fetchCommunities: () => Promise<void> }>();
    const [community, setCommunity] = useState<Community>({
        community_id: -1,
        name: '',
        description: '',
    });
    const { success, warn, error } = useAlertContext();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCreate = async () => {
        if (!community.name) {
            warn('名称必填');
            return;
        }
        setLoading(true);
        try {
            const response = await api.post('/api/community', community);
            success('创建成功');
            const c = await response.data;
            await fetchCommunities();
            navigate(`../community/${c.community_id}`);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`创建失败 ${message}`);
                setLoading(false);
            }
        }
    }
    return (
        <div className="space-y-4">
            <h5 className="text-xl font-medium text-gray-900">创建新族谱</h5>
            <div>
                <input type="text" name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                    placeholder="名称（必填）"
                    value={community.name}
                    onChange={(e) => {
                        setCommunity({ ...community, name: e.target.value });
                    }}
                    required />
            </div>
            <div>
                <textarea name="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full min-h-32 p-2.5"
                    placeholder="简介"
                    value={community.description}
                    onChange={(e) => {
                        setCommunity({ ...community, description: e.target.value });
                    }} />
            </div>
            <LoadingButton
                className="w-full text-white text-md bg-black hover:bg-gray-800 rounded px-5 py-2.5"
                loading={loading}
                text="创建"
                onClick={handleCreate}>
                <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                </svg>
            </LoadingButton>
        </div>
    );
}