import { useEffect, useState } from "react";
import { type Community } from "../../api/community";
import { useAlertContext } from "../../components/alert/AlertContext";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios, { AxiosError } from "axios";

export default function BasicInfo() {
    document.title = '基本信息';
    const { id } = useParams();
    const community_id = Number(id);
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState({
        deleting: false,
        updating: false,
    });
    const { success, warn, error } = useAlertContext();
    // 更新左侧菜单栏
    const { communities, fetchCommunities } = useOutletContext<{
        communities: Community[],
        fetchCommunities: () => Promise<void>
    }>();
    const [community, setCommunity] = useState<Community>({
        community_id: community_id,
        name: '',
    });

    useEffect(() => {
        setCommunity(communities.find(c => c.community_id === community_id)!);
    }, [id]);

    const modifyBasicInfo = async () => {
        if (!community.name) {
            warn('族谱名称必填');
            return;
        }
        setLoading({ ...loading, updating: true, });
        try {
            await api.put('/api/community', community);
            await fetchCommunities();
            setEditing(false);
            success('修改成功');
            setLoading({ ...loading, updating: false, });
        } catch (exception) {
            console.error('Error fetching data:', exception);
            if (exception instanceof AxiosError) {
                error(`修改失败 ${exception.response?.data}`);
            }
        }
    }

    const deleteCommunity = async () => {
        setLoading({ ...loading, deleting: true, });
        try {
            await api.delete(`/api/community`, {
                data: {
                    community_id: community_id
                }
            });
            await fetchCommunities();
            setLoading({ ...loading, deleting: false, });
            success('删除成功');
            navigate('../');
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`删除失败 ${message}`);
                setLoading({ ...loading, deleting: false, });
            }
        }
    }

    return (
        <div className="space-y-2">
            {editing ? <div className="space-y-2">
                <input type="text" name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                    placeholder="名称"
                    value={community.name}
                    onChange={(e) => {
                        setCommunity({ ...community, name: e.target.value });
                    }} />
                <textarea name="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full min-h-32 p-2.5"
                    placeholder="简介"
                    value={community.description}
                    onChange={(e) => {
                        setCommunity({ ...community, description: e.target.value });
                    }} />
            </div> : <dl className="text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pb-3">
                    <dt className="mb-1 text-gray-500">族谱名称</dt>
                    <dd className="text-lg font-semibold">{community.name}</dd>
                </div>
                <div className="flex flex-col pt-3">
                    <dt className="mb-1 text-gray-500">族谱简介</dt>
                    <dd className="text-lg font-semibold">{community.description}</dd>
                </div>
            </dl>}

            {editing ? <div className="text-center">
                <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded-s hover:bg-green-800"
                    loading={loading.updating}
                    text='提交'
                    onClick={async () => await modifyBasicInfo()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                </LoadingButton>
                <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-e hover:bg-gray-800"
                    type="button"
                    onClick={() => {
                        setEditing(false);
                        setCommunity(communities.find(c => c.community_id === community_id)!);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    取消
                </button>
            </div> : <div className="text-center">
                <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-s hover:bg-gray-800"
                    type="button"
                    onClick={() => setEditing(true)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                    修改
                </button>
                <LoadingButton className="px-4 py-2 text-sm text-white bg-red-700 rounded-e hover:bg-red-800"
                    loading={loading.deleting}
                    text='删除'
                    onClick={async () => await deleteCommunity()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </LoadingButton>
            </div>}
        </div>
    );
}
