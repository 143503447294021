import React from "react";
import ForceGraph2D from "react-force-graph-2d";
import { Login } from "./Login";
import { useDocumentTitleContext } from "../../common/document-title/DocumentTitleContext";

function genRandomTree(N = 300): { nodes: { id: number }[], links: { target: number, source: number }[] } {
    return {
        nodes: [...Array(N).keys()].map(i => ({ id: i })),
        links: [...Array(N).keys()]
            .filter(id => id)
            .map(id => ({
                source: id,
                target: Math.round(Math.random() * (id - 1))
            }))
    };
}

export default function Index() {
    const setTitle = useDocumentTitleContext()!;
    setTitle('🙋')

    const GROUPS = 12;
    const gData = genRandomTree(200);
    return (
        <React.Fragment>
            <div className="fixed top-0 bottom-0 left-0 right-0 -z-10">
                <ForceGraph2D
                    graphData={gData}
                    nodeAutoColorBy={d => (d.id % GROUPS).toString()}
                    linkAutoColorBy={l => (gData.nodes[l.source].id % GROUPS).toString()}
                    linkWidth={2}
                    nodeVal={n => n.id < 10 ? 10 : Math.log10(n.id)}
                    enableZoomInteraction={false}
                    enableNodeDrag={false}
                    enablePanInteraction={false}
                />
            </div>
            <div className="container mx-auto">
                <Login />
            </div>
        </React.Fragment>
    );
}
