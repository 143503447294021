import { createContext, useContext } from "react";

enum AlertType {
    success,
    warn,
    error,
}

interface Alert {
    id: number,
    type: AlertType,
    message: string,
}

interface AlertContextType {
    alerts: Alert[];
    success: (message: string) => void;
    warn: (message: string) => void;
    error: (message: string) => void;
    removeAlertById: (id: number) => void;
}

const fake = (message: string) => {
    console.log(`new alert message: ${message}`);
}

const AlertContext = createContext<AlertContextType>({
    alerts: [],
    success: fake,
    warn: fake,
    error: fake,
    removeAlertById: (id) => console.log(`alert with id ${id} removed`),
});

const useAlertContext = () => useContext(AlertContext);

export default AlertContext;
export { useAlertContext, AlertType };
export type { AlertContextType, Alert };