import axios from 'axios';
import { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken, clearTokens } from './api/TokenService';

// Create an Axios instance
const api = axios.create({
    // baseURL: 'https://api.example.com',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the access token in headers
api.interceptors.request.use(async config => {
    const token = await getAccessToken();
    if (token) {
        config.headers['Authorization'] = `${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add a response interceptor to handle token refresh
api.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const newAccessToken = await refreshAccessToken();
            await setAccessToken(newAccessToken);
            originalRequest.headers['Authorization'] = `${newAccessToken}`;
            return api(originalRequest);
        } catch (err) {
            clearTokens();
            return Promise.reject(err);
        }
    }
    return Promise.reject(error);
});

// Function to refresh the access token
const refreshAccessToken = async (): Promise<string> => {
    const refreshToken = await getRefreshToken();
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    const response = await axios.get('/api/user/access_token', {
        headers: {
            'Refresh-Token': refreshToken
        }
    });
    const { access_token } = response.data;

    await setAccessToken(access_token);

    return access_token;
};

export default api;
