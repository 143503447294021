import { createContext, useContext } from "react";

const setTitle = (title: string) => {
    document.title = title;
}

const DocumentTitleContext = createContext<((title: string) => void)>(setTitle);

const useDocumentTitleContext = () => useContext(DocumentTitleContext);

const DocumentTitleProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <DocumentTitleContext.Provider value={setTitle}>{children}</DocumentTitleContext.Provider>
    );
};

export { useDocumentTitleContext, DocumentTitleProvider };