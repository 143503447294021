import React from "react";
import { NavLink, Outlet, useOutletContext, useParams } from "react-router-dom";
import type { Community } from "../../api/community";

const managementTabs = ['基本信息', '用户管理', '家庭管理', '关系管理', '人员管理', '人员关系管理', '查看图谱']
const managementTabUris = ['.', './user', './family', './relationship', './person', './person-relation', './network-graph']
const memberTabs = ['家庭管理', '关系管理', '人员管理', '人员关系管理', '查看图谱']
const memberTabUris = ['./family', './relationship', './person', './person-relation', './network-graph']
const tabActiveStyle = 'inline-block p-4 text-black border-b-2 border-black rounded-t-lg active';
const tabInactiveStyle = 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300';

export default function Community() {
    const { id } = useParams();
    const community_id = Number(id);
    const outletContext = useOutletContext();
    const { communities } = useOutletContext<{ communities: Community[] }>();
    const community = communities.find(c => c.community_id === community_id);
    if (!community) {
        document.title = '找不到🥵';
        return (
            <p>404</p>
        );
    }

    const role = community.role;
    const tabs = role && role > 3 ? memberTabs : managementTabs;
    const tabUris = role && role > 3 ? memberTabUris : managementTabUris;
    return (
        <React.Fragment>
            <div className="text-sm font-medium text-center text-gray-500 mb-4">
                <ul className="flex flex-wrap -mb-px">
                    {tabs.map((tab, index) => (
                        <li key={index} className="me-2">
                            <NavLink to={tabUris[index]}
                                className={({ isActive }) =>
                                    isActive ? tabActiveStyle : tabInactiveStyle
                                }
                                end={tabUris[index] !== './family' && tabUris[index] !== './person'}>
                                {tab}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            <Outlet context={outletContext} />
        </React.Fragment>
    );
}
