// TokenService.ts
const dbName = 'auth-db';
const dbVersion = 1;
const accessTokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';

const openDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, dbVersion);

        request.onupgradeneeded = function (event) {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains('tokens')) {
                db.createObjectStore('tokens', { keyPath: 'key' });
            }
        };

        request.onsuccess = function (event) {
            resolve((event.target as IDBOpenDBRequest).result);
        };

        request.onerror = function (event) {
            reject('Error opening the database');
        };
    });
};

export const setAccessToken = async (token: string) => {
    const db = await openDB();
    const transaction = db.transaction('tokens', 'readwrite');
    const store = transaction.objectStore('tokens');
    store.put({ key: accessTokenKey, value: token });
};

export const getAccessToken = async (): Promise<string | null> => {
    const db = await openDB();
    const transaction = db.transaction('tokens', 'readonly');
    const store = transaction.objectStore('tokens');
    return new Promise((resolve, reject) => {
        const request = store.get(accessTokenKey);
        request.onsuccess = () => {
            resolve(request.result?.value || null);
        };
        request.onerror = () => {
            reject('Error fetching the access token');
        };
    });
};

export const setRefreshToken = async (token: string) => {
    const db = await openDB();
    const transaction = db.transaction('tokens', 'readwrite');
    const store = transaction.objectStore('tokens');
    store.put({ key: refreshTokenKey, value: token });
};

export const getRefreshToken = async (): Promise<string | null> => {
    const db = await openDB();
    const transaction = db.transaction('tokens', 'readonly');
    const store = transaction.objectStore('tokens');
    return new Promise((resolve, reject) => {
        const request = store.get(refreshTokenKey);
        request.onsuccess = () => {
            resolve(request.result?.value || null);
        };
        request.onerror = () => {
            reject('Error fetching the refresh token');
        };
    });
};

export const clearTokens = async () => {
    const db = await openDB();
    const transaction = db.transaction('tokens', 'readwrite');
    const store = transaction.objectStore('tokens');
    store.delete(accessTokenKey);
    store.delete(refreshTokenKey);
};
