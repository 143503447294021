import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlertContext } from "../../components/alert/AlertContext";
import { LoadingButton } from "../../components/Button";
import axios from "axios";
import { setAccessToken, setRefreshToken } from "../../api/TokenService";

interface LoginForm {
    tel: string,
    captcha: string,
    agree: boolean,
}

export function Login() {
    const { success, warn } = useAlertContext()!;
    const countDownBtText = '获取验证码';
    const [form, setForm] = useState<LoginForm>({
        tel: '17785612709',
        captcha: '',
        agree: false,
    });

    const [countDownText, setCountDownText] = useState(countDownBtText);
    const [canSendCaptcha, setCanSendCaptcha] = useState(true);
    const [canLogin, setCanLogin] = useState(true);

    const navigate = useNavigate();

    const sendCaptcha = async () => {
        setCanSendCaptcha(false);
        try {
            await axios.get(`/api/user/captcha/${form.tel}`);
            success('验证码已发送');
            let totalSecs = 60;
            const countDown = setInterval(() => {
                totalSecs--;
                setCountDownText(`${totalSecs} S`);
                if (totalSecs <= 0) {
                    clearInterval(countDown);
                    setCountDownText(countDownBtText);
                    setCanSendCaptcha(true);
                }
            }, 1000);
        } catch (error) {
            console.error('验证码发送失败:', error);
            warn('验证码发送失败');
            setCanSendCaptcha(true);
        }
    }

    const login = async () => {
        if (!form.agree) {
            warn('请同意隐私条款');
            return;
        }
        setCanLogin(false);
        try {
            const response = await axios.post('/api/user/login', {
                mobile: form.tel,
                captcha: form.captcha,
            });
            const { access_token, refresh_token } = response.data;
            await setAccessToken(access_token);
            await setRefreshToken(refresh_token);
            // Redirect or update UI after successful login
            navigate('/home');
        } catch (error) {
            console.error('Login failed:', error);
            setCanLogin(true);
            warn(`登录失败 ${error}`);
        }
    }

    return (
        <div className="max-w-sm ml-auto mt-32 space-y-4">
            <h5 className="text-xl font-medium text-gray-900">使用手机号登录</h5>
            <div>
                <input type="tel"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                    placeholder="手机号📱"
                    value={form.tel}
                    onChange={(e) => setForm({ ...form, tel: e.target.value })} />
            </div>
            <div className="flex">
                <input type="text"
                    className="max-w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                    placeholder="000000"
                    value={form.captcha}
                    onChange={(e) => setForm({ ...form, captcha: e.target.value })} />
                <LoadingButton className="ms-auto bg-black rounded px-2 text-white text-sm min-w-28 hover:bg-gray-800"
                    text={countDownText}
                    loading={!canSendCaptcha}
                    onClick={sendCaptcha}>
                    <svg className="w-3.5 h-3.5 me-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>
                </LoadingButton>
            </div>
            <div className="flex space-x-1">
                <input className="border border-gray-300 rounded bg-gray-50"
                    type="checkbox" checked={form.agree}
                    onChange={() => setForm({ ...form, agree: !form.agree })} />
                <span className="text-sm">
                    同意<Link to="https://baidu.com" className="text-blue-700 hover:underline">隐私条款</Link>
                </span>
            </div>
            <LoadingButton className="w-full text-white text-md bg-black font-medium rounded px-5 py-2.5 hover:bg-gray-800"
                text="登录"
                loading={!canLogin}
                onClick={login}>
                <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
            </LoadingButton>
        </div>
    );
}