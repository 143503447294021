import { useEffect, useState } from "react";
import { useAlertContext } from "../../components/alert/AlertContext";
import { useParams } from "react-router-dom";
import type { CommunityPerson } from "../../api/community/person";
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios from "axios";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import type { CommunityFamily } from "../../api/community";

enum Status {
    edit,
    view,
}

export default function PersonManagement() {
    const { id } = useParams();
    const community_id = Number(id);
    const { success, warn, error } = useAlertContext();
    const [families, setFamilies] = useState<CommunityFamily[]>([]);
    const [persons, setPersons] = useState<CommunityPerson[]>([]);
    const [person, setPerson] = useState<CommunityPerson>();
    const [status, setStatus] = useState<Status>(Status.view);
    const [searching, setSearching] = useState<boolean>(false);
    const [selectedFamilyId, setSelectedFamilyId] = useState<number>(0);

    const fetchFamilies = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/families`);
            setFamilies(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`家庭列表查询失败 ${e.response?.data}`);
            }
        }
    };
    const fetchPersons = async () => {
        try {
            let url = `/api/community/${community_id}/persons`;
            if (selectedFamilyId > 0) {
                url += `?familyId=${selectedFamilyId}`;
            }
            const response = await api.get(url);
            setPersons(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                error(`人员查询失败 ${e.response?.data}`);
            }
        }
    };
    const addPerson = async (person: CommunityPerson) => {
        if (!person.full_name) {
            warn('姓名必填');
            return;
        }
        try {
            await api.post('/api/community/person', person);
            success('添加成功');
            setStatus(Status.view);
            await fetchPersons();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`添加失败 ${message}`);
            }
        }
    }
    const updatePerson = async (person: CommunityPerson) => {
        try {
            await api.put('/api/community/person', person);
            success('修改成功');
            setStatus(Status.view);
            setPerson(undefined);
            await fetchPersons();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`修改失败 ${message}`);
            }
        }
    };
    const deletePerson = async (person: CommunityPerson) => {
        try {
            await api.delete('/api/community/person', {
                data: person
            });
            success('删除成功');
            await fetchPersons();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`删除失败 ${message}`);
            }
        }
    };
    useEffect(() => {
        fetchFamilies().then(async () => await fetchPersons());
    }, [id]);

    if (status === Status.edit) {
        return (
            <PersonForm personToUpdate={person}
                families={families}
                onAdd={addPerson}
                onUpdate={updatePerson}
                onCancel={() => setStatus(Status.view)} />
        );
    }
    return (
        <div className="space-y-4">
            <div>
                <span className="space-x-1">
                    <select className="bg-gray-50 w-60 text-black text-sm rounded p-2.5"
                        value={selectedFamilyId}
                        onChange={(e) => setSelectedFamilyId(Number(e.target.options[e.target.selectedIndex].value))}>
                        <option value={-1}>请选择家庭</option>
                        {families.map((f) => (
                            <option key={f.family_id} value={f.family_id}>{f.name}</option>
                        ))}
                    </select>
                    <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded hover:bg-green-800"
                        loading={searching}
                        text='搜索'
                        onClick={async () => {
                            setSearching(true);
                            await fetchPersons();
                            setSearching(false);
                        }}>
                        <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </LoadingButton>
                </span>
                <LoadingButton className="px-4 py-2 ms-2 text-sm text-white inline-flex items-center bg-black rounded hover:bg-gray-800"
                    loading={false}
                    text='添加新人员'
                    onClick={() => setStatus(Status.edit)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>
            </div>
            <div className="grid grid-cols-4 gap-4">
                {persons.map(person => (
                    <PersonCard key={person.person_id}
                        person={person}
                        onUpdate={(p) => {
                            setPerson(p);
                            setStatus(Status.edit);
                        }}
                        onDelete={deletePerson} />
                ))}
            </div>
        </div>
    );
}

function PersonForm({ personToUpdate, families, onAdd, onUpdate, onCancel }:
    {
        personToUpdate?: CommunityPerson,
        families: CommunityFamily[],
        onAdd: (person: CommunityPerson) => Promise<void>,
        onUpdate: (person: CommunityPerson) => Promise<void>,
        onCancel: () => void,
    }) {
    const { id } = useParams();
    const community_id = Number(id);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const { error } = useAlertContext();

    const [person, setPerson] = useState<CommunityPerson>(personToUpdate || {
        person_id: -1,
        community_id: community_id,
        family_id: -1,
        avatar_url: '',
        full_name: '',
        gender: 0,
        birthdate: '',
        birthplace: '',
        date_of_death: '',
        place_of_death: '',
        brief_introduction: ''
    });
    const avatarClass = person.avatar_url ? '' : ' size-36 border-2 border-gray-300 border-dashed bg-gray-50 hover:bg-gray-100';

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setUploading(true);
            const imageFile = e.target.files[0];
            const formData = new FormData();
            formData.append('image', imageFile);
            try {
                const response = await api.post('/api/community/person/avatar', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setPerson({ ...person, avatar_url: response.data });
            } catch (e) {
                console.error('Error uploading image:', e);
                error(`头像上传失败 ${e}`);
            }
            setUploading(false);
        }
    };

    return (
        <div className="w-1/3 mx-auto bg-white flex flex-col items-center pb-4 pt-4 space-y-3">
            <label className={`flex flex-col items-center justify-center rounded-full cursor-pointer ${avatarClass}`}>
                {person.avatar_url ? <img className="size-36 rounded-full shadow-lg"
                    src={`/api/community/person/avatar/${person.avatar_url}`}
                    alt="Avatar" /> : uploading ? <svg className="size-8 animate-spin" aria-hidden="true" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg> : <svg className="size-8 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                </svg>}
                <input type="file" accept="image/*" className="hidden"
                    onChange={handleImageChange} />
            </label>
            <input type="text"
                className="p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                placeholder="姓名"
                value={person.full_name}
                onChange={(e) => setPerson({ ...person, full_name: e.target.value })} />
            <textarea className="p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white min-h-32"
                placeholder="人物简介"
                value={person.brief_introduction}
                onChange={(e) => setPerson({ ...person, brief_introduction: e.target.value })} />
            <ul className="flex w-full space-x-4 justify-center text-sm">
                <li>
                    <input type="radio"
                        id="male-radio"
                        name="gender"
                        value="0"
                        checked={person.gender === 0}
                        className="hidden peer"
                        onChange={(e) => setPerson({ ...person, gender: Number(e.target.value) })} />
                    <label htmlFor="male-radio"
                        className="inline-flex items-center justify-between py-2 px-4 text-blue-700 bg-transparent border border-blue-600 rounded cursor-pointer peer-checked:bg-blue-800 peer-checked:text-white hover:text-white hover:bg-blue-700">
                        <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8" />
                        </svg>
                        男性
                    </label>
                </li>
                <li>
                    <input type="radio"
                        id="female-radio"
                        name="gender"
                        value="1"
                        checked={person.gender === 1}
                        className="hidden peer"
                        onChange={(e) => setPerson({ ...person, gender: Number(e.target.value) })} />
                    <label htmlFor="female-radio"
                        className="inline-flex items-center justify-between py-2 px-4 text-pink-700 bg-transparent border border-pink-600 rounded cursor-pointer peer-checked:bg-pink-800 peer-checked:text-white hover:text-white hover:bg-pink-700">
                        <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5" />
                        </svg>
                        女性
                    </label>
                </li>
            </ul>
            <Datepicker
                i18n={"zh-cn"}
                primaryColor={"orange"}
                placeholder="出生日期"
                minDate={new Date('1900-01-01')}
                maxDate={new Date()}
                startFrom={new Date()}
                containerClassName="relative w-full"
                inputClassName="relative transition-all duration-300 p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                readOnly={true}
                useRange={false}
                asSingle={true}
                value={{
                    startDate: person.birthdate || null,
                    endDate: person.birthdate || null,
                }}
                onChange={(value: DateValueType) => {
                    let birthdate = !value ? undefined : !value.startDate ? undefined : value.startDate;
                    if (birthdate instanceof Date) {
                        birthdate = `${birthdate.getFullYear()}-${String(birthdate.getMonth() + 1).padStart(2, '0')}-${String(birthdate.getDate() + 1).padStart(2, '0')}`
                    }
                    setPerson({ ...person, birthdate: birthdate })
                }}
            />
            <input type="text"
                className="p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                placeholder="出生地点"
                value={person.birthplace}
                onChange={(e) => setPerson({ ...person, birthplace: e.target.value })} />
            <Datepicker
                i18n={"zh-cn"}
                primaryColor={"orange"}
                placeholder="死亡日期"
                minDate={new Date('1900-01-01')}
                maxDate={new Date()}
                startFrom={new Date()}
                containerClassName="relative w-full"
                inputClassName="relative transition-all duration-300 p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                readOnly={true}
                useRange={false}
                asSingle={true}
                value={{
                    startDate: person.date_of_death || null,
                    endDate: person.date_of_death || null,
                }}
                onChange={(value: DateValueType) => {
                    let date_of_death = !value ? undefined : !value.startDate ? undefined : value.startDate;
                    if (date_of_death instanceof Date) {
                        date_of_death = `${date_of_death.getFullYear()}-${String(date_of_death.getMonth() + 1).padStart(2, '0')}-${String(date_of_death.getDate() + 1).padStart(2, '0')}`
                    }
                    setPerson({ ...person, date_of_death: date_of_death })
                }}
            />
            <input type="text"
                className="p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                placeholder="死亡地点"
                value={person.place_of_death}
                onChange={(e) => setPerson({ ...person, place_of_death: e.target.value })} />
            <select className="p-2.5 w-full border border-gray-300 text-gray-900 rounded text-sm bg-white"
                value={person.family_id}
                onChange={(e) => {
                    setPerson({
                        ...person,
                        family_id: Number(e.target.options[e.target.selectedIndex].value),
                    });
                }}>
                <option value={0}>请选择</option>
                {families.map((f) => (
                    <option key={f.family_id} value={f.family_id}>{f.name}</option>
                ))}
            </select>
            <div className="flex mt-4 space-x-4">
                {personToUpdate ? <LoadingButton className="px-4 py-2 rounded text-sm text-green-700 inline-flex items-center hover:text-white border border-green-700 hover:bg-green-800"
                    loading={loading}
                    text='修改'
                    onClick={async () => {
                        setLoading(true);
                        await onUpdate(person);
                        setLoading(false);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                </LoadingButton> : <LoadingButton className="px-4 py-2 rounded text-sm text-green-700 inline-flex items-center hover:text-white border border-green-700 hover:bg-green-800"
                    loading={loading}
                    text='添加'
                    onClick={async () => {
                        setLoading(true);
                        await onAdd(person);
                        setLoading(false);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>}
                <button className="px-4 py-2 rounded text-sm text-black inline-flex items-center hover:text-white border border-gray-700 hover:bg-gray-800"
                    type="button"
                    onClick={onCancel}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    取消
                </button>
            </div>
        </div>
    );
}

function PersonCard({ person, onUpdate, onDelete }:
    {
        person: CommunityPerson,
        onUpdate: (person: CommunityPerson) => void,
        onDelete: (person: CommunityPerson) => Promise<void>,
    }) {
    const [deleting, setDeleting] = useState<boolean>(false);
    return (
        <div className="w-full max-w-sm bg-white border border-gray-200 rounded flex flex-col items-center py-4 px-2">
            <img className="size-36 mb-3 rounded-full shadow-lg"
                src={`/api/community/person/avatar/${person.avatar_url}`}
                alt="Avatar" />
            <h5 className="mb-1 text-xl font-medium text-gray-900 inline-flex items-center">
                {person.full_name}
                {Number(person.gender) + 1 && person.gender === 0 ? <svg className="size-4 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8" />
                </svg> : person.gender === 1 ? <svg className="size-4 text-pink-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5" />
                </svg> : <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M11.5 1a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-3.45 3.45A4 4 0 0 1 8.5 10.97V13H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V14H6a.5.5 0 0 1 0-1h1.5v-2.03a4 4 0 1 1 3.471-6.648L14.293 1zm-.997 4.346a3 3 0 1 0-5.006 3.309 3 3 0 0 0 5.006-3.31z" />
                </svg>}
            </h5>
            <span className="text-sm text-gray-500">{person.brief_introduction}</span>
            <span className="text-sm text-gray-500 inline-flex items-center">
                <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="m7.994.013-.595.79a.747.747 0 0 0 .101 1.01V4H5a2 2 0 0 0-2 2v3H2a2 2 0 0 0-2 2v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a2 2 0 0 0-2-2h-1V6a2 2 0 0 0-2-2H8.5V1.806A.747.747 0 0 0 8.592.802zM4 6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v.414a.9.9 0 0 1-.646-.268 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0A.9.9 0 0 1 4 6.414zm0 1.414c.49 0 .98-.187 1.354-.56a.914.914 0 0 1 1.292 0c.748.747 1.96.747 2.708 0a.914.914 0 0 1 1.292 0c.374.373.864.56 1.354.56V9H4zM1 11a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.793l-.354.354a.914.914 0 0 1-1.293 0 1.914 1.914 0 0 0-2.707 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0L1 11.793zm11.646 1.854a1.915 1.915 0 0 0 2.354.279V15H1v-1.867c.737.452 1.715.36 2.354-.28a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.708 0a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.707 0a.914.914 0 0 1 1.293 0Z" />
                </svg>
                {person.birthdate || '--'}
            </span>
            <span className="text-sm text-gray-500 inline-flex items-center">
                <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                </svg>
                {person.birthplace || '--'}
            </span>
            <span className="text-sm text-gray-500 inline-flex items-center">
                <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                </svg>
                {person.date_of_death || '--'}
            </span>
            <span className="text-sm text-gray-500 inline-flex items-center">
                <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                </svg>
                {person.place_of_death || '--'}
            </span>
            <span className="text-sm text-gray-500 inline-flex items-center">
                <svg className="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.309 8 6.982" />
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.707L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.646a.5.5 0 0 0 .708-.707L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                </svg>
                {person.family_name || '--'}
            </span>
            <div className="flex mt-4 space-x-4">
                <button className="px-4 py-2 rounded text-sm text-green-700 inline-flex items-center hover:text-white border border-green-700 hover:bg-green-800"
                    type="button"
                    onClick={() => {
                        onUpdate(person);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                    修改
                </button>
                <LoadingButton className="px-4 py-2 rounded text-sm text-red-700 inline-flex items-center hover:text-white border border-red-700 hover:bg-red-800"
                    loading={deleting}
                    text='删除'
                    onClick={async () => {
                        setDeleting(true);
                        await onDelete(person);
                        setDeleting(false);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </LoadingButton>
            </div>
        </div>
    );
}
