import ForceGraph2D from 'react-force-graph-2d';
import { useParams } from 'react-router-dom';
import type { CommunityPersonRelationship } from '../../api/community/personRelationship';
import { useEffect, useState } from 'react';
import api from '../../api';
import axios from 'axios';
import { useAlertContext } from '../../components/alert/AlertContext';

interface Node {
    id: number,
    label: string,
    url: string,
    img: HTMLImageElement,
}
interface Link {
    source: number,
    target: number,
    name: string,
}

export default function NetworkGraph() {
    const { id } = useParams();
    const community_id = Number(id);
    const { warn } = useAlertContext();
    // const [personRelations, setPersonRelations] = useState<CommunityPersonRelationship[]>([]);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [links, setLinks] = useState<Link[]>([]);
    const fetchPersonRelations = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/person-relationships`);
            const personRelations: CommunityPersonRelationship[] = response.data;
            const persons: Node[] = [];
            const relationships: Link[] = [];
            personRelations.forEach(pr => {
                if (persons.findIndex(p => p.id === pr.subject_person_id) < 0) {
                    const url = pr.subject_person_avatar ? `/api/community/person/avatar/${pr.subject_person_avatar}` : '';
                    const img = new Image();
                    img.src = url
                    persons.push({
                        id: pr.subject_person_id!,
                        label: pr.subject_person_name || '无名氏',
                        url: url,
                        img: img,
                    });
                }
                if (persons.findIndex(p => p.id === pr.related_person_id) < 0) {
                    const url = pr.related_person_avatar ? `/api/community/person/avatar/${pr.related_person_avatar}` : '';
                    const img = new Image();
                    img.src = url
                    persons.push({
                        id: pr.related_person_id!,
                        label: pr.related_person_name || '无名氏',
                        url: url,
                        img: img,
                    });
                }
                relationships.push({
                    source: pr.subject_person_id!,
                    target: pr.related_person_id!,
                    name: pr.relationship_name!,
                });
            });
            setNodes(persons);
            setLinks(relationships);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`关系查询失败 ${e.response?.data}`);
            }
        }
    }
    const graphData = {
        nodes: nodes,
        links: links,
    };

    useEffect(() => {
        fetchPersonRelations();
    }, [id]);

    return (
        <div>
            <ForceGraph2D
                graphData={graphData}
                minZoom={5}
                maxZoom={10}
                nodeLabel="label"
                linkWidth={3}
                dagMode='td'
                linkDirectionalParticles={2}
                linkDirectionalParticleWidth={2}
                dagLevelDistance={30}
                d3VelocityDecay={0.3}
                linkAutoColorBy={l => l.source.toString()}
                nodeCanvasObject={(node, ctx, scale) => {
                    const radius = 2 * scale;
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(node.x!, node.y!, radius, 0, Math.PI * 2);
                    ctx.closePath();
                    ctx.clip();
                    ctx.drawImage(node.img,
                        node.x! - radius,
                        node.y! - radius,
                        2 * radius,
                        2 * radius,);
                    ctx.restore();
                }}
            />
        </div>
    );
}