import { useEffect, useState } from "react";
import { useAlertContext } from "../../components/alert/AlertContext";
import type { CommunityPersonRelationship } from "../../api/community/personRelationship";
import { useParams } from "react-router-dom";
import type { CommunityRelationship } from "../../api/community/relationship";
import type { CommunityPerson } from "../../api/community/person";
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios from "axios";
export default function PersonRelationManagement() {
    const { id } = useParams();
    const community_id = Number(id);
    const [personRelations, setPersonRelations] = useState<CommunityPersonRelationship[]>([]);
    const [relationships, setRelationships] = useState<CommunityRelationship[]>([]);
    const [persons, setPersons] = useState<CommunityPerson[]>([]);
    const [selectedFamilyId, setSelectedFamilyId] = useState<number>(0);

    const [showAddForm, setShowAddForm] = useState(false);
    const { success, warn, error } = useAlertContext();

    const fetchPersonRelations = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/person-relationships`);
            setPersonRelations(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`关系查询失败 ${e.response?.data}`);
            }
        }
    }
    const fetchRelationships = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/relationships`);
            setRelationships(response.data);
            // setRelationship({ ...relationship, relationship_name: '' });
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`关系查询失败 ${e.response?.data}`);
            }
        }
    }
    const fetchPersons = async () => {
        try {
            let url = `/api/community/${community_id}/persons`;
            if (selectedFamilyId > 0) {
                url += `?familyId=${selectedFamilyId}`;
            }
            const response = await api.get(url);
            setPersons(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                error(`人员查询失败 ${e.response?.data}`);
            }
        }
    };
    const addPersonRelation = async (personRelation: CommunityPersonRelationship) => {
        if (!personRelation.subject_person_id || !personRelation.relationship_id || !personRelation.related_person_id) {
            warn('未正确输入');
            return;
        }
        try {
            personRelation.community_id = community_id;
            await api.post('/api/community/person-relationship', personRelation);
            success('添加成功');
            setShowAddForm(false);
            await fetchPersonRelations();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`添加失败 ${message}`);
            }
        }
    }
    const removePersonRelation = async (id: number) => {
        try {
            await api.delete('/api/community/person-relationship', {
                data: {
                    person_relationship_id: id,
                }
            });
            success('删除成功');
            await fetchPersonRelations();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`删除失败 ${message}`);
            }
        }
    }

    useEffect(() => {
        fetchPersonRelations().then(async () => {
            await fetchRelationships();
            await fetchPersons();
        });
    }, [id]);

    return (
        <div className="space-y-2">
            {showAddForm ? <EditItem persons={persons} relationships={relationships}
                onAdd={addPersonRelation}
                onCancel={() => setShowAddForm(false)} /> : <button className="text-white bg-black hover:bg-gray-800 rounded text-sm px-4 py-2"
                    type="button"
                    onClick={() => setShowAddForm(true)}>
                添加
            </button>}
            <div className="w-full space-y-1">
                {personRelations.map(pr => (
                    <Item key={pr.person_relationship_id} cpr={pr} onDelete={removePersonRelation} />
                ))}
            </div>
        </div>
    );
}

function Item({ cpr, onDelete }: { cpr: CommunityPersonRelationship, onDelete: (id: number) => Promise<void>, }) {
    const [deleting, setDeleting] = useState<boolean>(false);
    return (
        <div className="w-full inline-flex items-center justify-around bg-white border border-gray-200 rounded py-4 px-2">
            <div className="relative inline-flex items-center justify-center w-2/3">
                <span className="text-center">
                    <img className="size-16 rounded-full shadow-lg"
                        src={`/api/community/person/avatar/${cpr.subject_person_avatar}`}
                        alt="Avatar" />
                    <label>{cpr.subject_person_name}</label>
                </span>
                <hr className="w-1/2 h-px my-2 bg-gray-900 border-0" />
                <span className="absolute -translate-x-1/2 left-1/2 px-3 font-medium text-gray-900 bg-white">
                    的
                    <span className="mx-2 px-2 w-20">{cpr.relationship_name}</span>
                    是
                </span>
                <span className="text-center">
                    <img className="size-16 rounded-full shadow-lg"
                        src={`/api/community/person/avatar/${cpr.related_person_avatar}`}
                        alt="Avatar" />
                    <label>{cpr.related_person_name}</label>
                </span>
            </div>
            <LoadingButton className="px-4 py-2 rounded text-sm text-red-700 inline-flex items-center hover:text-white border border-red-700 hover:bg-red-800"
                loading={deleting}
                text='删除'
                onClick={async () => {
                    setDeleting(true);
                    await onDelete(cpr.person_relationship_id);
                    setDeleting(false);
                }}>
                <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                </svg>
            </LoadingButton>
        </div >
    );
}

function EditItem({ persons, relationships, onAdd, onCancel }:
    {
        persons: CommunityPerson[],
        relationships: CommunityRelationship[],
        onAdd: (cpr: CommunityPersonRelationship) => Promise<void>,
        onCancel: () => void,
    }) {
    const [personRelation, setPersonRelation] = useState<CommunityPersonRelationship>({
        person_relationship_id: -1,
        community_id: -1,
        subject_person_id: 0,
        subject_person_name: '',
        subject_person_avatar: '',
        relationship_id: 0,
        related_person_id: 0,
        related_person_name: '',
        related_person_avatar: '',
    });
    const [adding, setAdding] = useState<boolean>(false);

    return (
        <div className="w-full inline-flex items-center justify-around bg-white border border-gray-200 rounded py-4 px-2">
            <div className="relative inline-flex items-center justify-center w-2/3">
                <span className="text-center">
                    {personRelation.subject_person_name ? <img className="size-16 rounded-full shadow-lg"
                        src={`/api/community/person/avatar/${personRelation.subject_person_avatar}`}
                        alt="Avatar" /> : <select className="block size-16 p-2 text-sm text-gray-900 border border-gray-300 rounded-full"
                            value={personRelation.subject_person_id}
                            onChange={(e) => {
                                const person_id = Number(e.target.options[e.target.selectedIndex].value);
                                const sp = persons.find(p => p.person_id === person_id);
                                setPersonRelation({
                                    ...personRelation,
                                    subject_person_id: person_id,
                                    subject_person_name: sp?.full_name,
                                    subject_person_avatar: sp?.avatar_url,
                                });
                            }}>
                        <option hidden></option>
                        {persons.filter(p => p.person_id !== personRelation.related_person_id).map(p => (
                            <option key={p.person_id} value={p.person_id}>{p.full_name}</option>
                        ))}
                    </select>}
                    <label>{personRelation.subject_person_name || '请选择'}</label>
                </span>
                <hr className="w-1/2 h-px my-2 bg-gray-900 border-0" />
                <span className="absolute -translate-x-1/2 left-1/2 px-3 font-medium text-gray-900 bg-white">
                    的
                    <select className="w-20 p-2 mx-2 text-center text-sm text-gray-900 border border-gray-300 rounded"
                        value={personRelation.relationship_id}
                        onChange={(e) => {
                            setPersonRelation({
                                ...personRelation,
                                relationship_id: Number(e.target.options[e.target.selectedIndex].value),
                            });
                        }}>
                        <option hidden></option>
                        {relationships.map(r => (
                            <option key={r.relationship_id} value={r.relationship_id}>
                                {r.relationship_name}
                            </option>
                        ))}
                    </select>
                    是
                </span>
                <span className="text-center">
                    {personRelation.related_person_name ? <img className="size-16 rounded-full shadow-lg"
                        src={`/api/community/person/avatar/${personRelation.related_person_avatar}`}
                        alt="Avatar" /> : <select className="block size-16 p-2 text-sm text-gray-900 border border-gray-300 rounded-full"
                            value={personRelation.related_person_id}
                            onChange={(e) => {
                                const person_id = Number(e.target.options[e.target.selectedIndex].value);
                                const rp = persons.find(p => p.person_id === person_id);
                                setPersonRelation({
                                    ...personRelation,
                                    related_person_id: person_id,
                                    related_person_name: rp?.full_name,
                                    related_person_avatar: rp?.avatar_url,
                                });
                            }}>
                        <option hidden></option>
                        {persons.filter(p => p.person_id !== personRelation.subject_person_id).map(p => (
                            <option key={p.person_id} value={p.person_id}>{p.full_name}</option>
                        ))}
                    </select>}
                    <label>{personRelation.related_person_name || '请选择'}</label>
                </span>
            </div>
            <div className="flex flex-col">
                <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded-t hover:bg-green-800"
                    loading={adding}
                    text='添加'
                    onClick={async () => {
                        setAdding(true);
                        await onAdd(personRelation);
                        setAdding(false);
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>
                <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-b hover:bg-gray-800"
                    type="button"
                    onClick={onCancel}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    取消
                </button>
            </div>
        </div >
    );
}