import { useEffect, useState } from "react";
import type { CommunityRelationship } from "../../api/community/relationship";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../components/alert/AlertContext";
import { LoadingButton } from "../../components/Button";
import api from "../../api";
import axios from "axios";

export default function RelationManagement() {
    const { id } = useParams();
    const community_id = Number(id);
    const [relationship, setRelationship] = useState<CommunityRelationship>({
        relationship_id: -1,
        community_id: community_id,
        relationship_name: '',
    });
    const [loading, setLoading] = useState(false);
    const [relationships, setRelationships] = useState<CommunityRelationship[]>([]);
    const { success, warn, error } = useAlertContext();

    const fetchRelationships = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/relationships`);
            setRelationships(response.data);
            setRelationship({ ...relationship, relationship_name: '' });
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`查询失败 ${e.response?.data}`);
            }
        }
    }

    const addRelationship = async () => {
        if (!relationship.relationship_name) {
            warn('名称必填');
            return;
        }
        setLoading(true);
        try {
            await api.post('/api/community/relationship', relationship);
            success('添加成功');
            await fetchRelationships();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`添加失败 ${message}`);
            }
        }
        setLoading(false);
    }

    const modifyRelationship = async (relation: CommunityRelationship) => {
        if (!relation.relationship_name) {
            warn('名称必填');
            return;
        }
        try {
            await api.put(`/api/community/relationship`, relation);
            success('修改成功');
            await fetchRelationships();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`修改失败 ${message}`);
            }
        }
    }

    const removeRelationship = async (relation: CommunityRelationship) => {
        try {
            await api.delete(`/api/community/relationship`, {
                data: relation
            });
            success('删除成功');
            await fetchRelationships();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`删除失败 ${message}`);
            }
        }
    }

    useEffect(() => {
        fetchRelationships();
    }, [id]);

    return (
        <div className="space-y-4">
            <div className="space-x-4">
                <input type="text" name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 w-1/5 p-2"
                    placeholder="名称"
                    value={relationship.relationship_name}
                    onChange={(e) => {
                        setRelationship({ ...relationship, relationship_name: e.target.value });
                    }} />
                <LoadingButton
                    className="text-white text-sm bg-black hover:bg-gray-800 rounded px-4 py-2 text-center"
                    loading={loading}
                    text="添加"
                    onClick={async () => {
                        await addRelationship();
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            序号
                        </th>
                        <th scope="col" className="px-6 py-3">
                            名称
                        </th>
                        <th scope="col" className="px-6 py-3">
                            管理
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {relationships.map(relation => (
                        <RowItem key={relation.relationship_id}
                            relation={relation}
                            modifyRelationship={modifyRelationship}
                            removeRelationship={removeRelationship} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function RowItem({ relation, modifyRelationship, removeRelationship }:
    {
        relation: CommunityRelationship,
        modifyRelationship: (relation: CommunityRelationship) => Promise<void>,
        removeRelationship: (relation: CommunityRelationship) => Promise<void>
    }) {
    const [r, setR] = useState<CommunityRelationship>(relation);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState({
        deleting: false,
        updating: false,
    });
    return (
        <tr className="odd:bg-white even:bg-gray-50 border-b">
            <td className="px-6 py-4">
                {r.relationship_id + 1}
            </td>
            <td className="px-6 py-4">
                {editing ? <input type="text" name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded p-2.5"
                    placeholder="名称"
                    value={r.relationship_name}
                    disabled={!editing}
                    onChange={(e) => {
                        setR({ ...r, relationship_name: e.target.value });
                    }} /> : r.relationship_name}
            </td>
            <td className="px-6 py-4">
                {editing ? <div className="text-center">
                    <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded-s hover:bg-green-800"
                        loading={loading.updating}
                        text='提交'
                        onClick={async () => {
                            setLoading({ ...loading, updating: true });
                            await modifyRelationship(r);
                            setLoading({ ...loading, updating: false });
                            setEditing(false);
                        }}>
                        <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </LoadingButton>
                    <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-e hover:bg-gray-800"
                        type="button"
                        onClick={() => {
                            setEditing(false);
                        }}>
                        <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                        取消
                    </button>
                </div> : <div className="text-center">
                    <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-s hover:bg-gray-800"
                        type="button"
                        onClick={() => setEditing(true)}>
                        <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                        修改
                    </button>
                    <LoadingButton className="px-4 py-2 text-sm text-white bg-red-700 rounded-e hover:bg-red-800"
                        loading={loading.deleting}
                        text='删除'
                        onClick={async () => {
                            setLoading({ ...loading, deleting: true });
                            await removeRelationship(r);
                            setLoading({ ...loading, deleting: false });
                        }}>
                        <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                        </svg>
                    </LoadingButton>
                </div>}
            </td>
        </tr>
    );
}