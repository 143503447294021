import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import type { CommunityFamily, CommunityFamilyUser, CommunityUser } from "../../api/community";
import { useAlertContext } from "../../components/alert/AlertContext";
import { useEffect, useState } from "react";
import { LoadingButton } from "../../components/Button";
import avatar from '../../avatar.jpg'
import api from "../../api";
import axios from "axios";

export default function FamilyManagement() {
    return (
        <Outlet />
    );
}

export function FamilyList() {
    const { id } = useParams();
    const community_id = Number(id);
    const { success, warn } = useAlertContext();
    const [families, setFamilies] = useState<CommunityFamily[]>([]);
    const fetchFamilies = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/families`);
            setFamilies(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                warn(`查询失败 ${e.response?.data}`);
            }
        }
    };
    const updateFamily = async (f: CommunityFamily) => {
        try {
            await api.put(`/api/community/family`, f);
            success('修改成功');
            await fetchFamilies();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                warn(`修改失败 ${message}`);
            }
        }
    };
    const removeFamily = async (f: CommunityFamily) => {
        try {
            await api.delete(`/api/community/family`, {
                data: f
            });
            success('删除成功');
            await fetchFamilies();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                warn(`删除失败 ${message}`);
            }
        }
    };
    useEffect(() => {
        fetchFamilies();
    }, [id]);
    return (
        <div className="space-y-4">
            <Link className="text-white text-sm bg-black hover:bg-gray-800 rounded px-5 py-2.5 text-center"
                to="add">
                添加新家庭
            </Link>
            <dl className="text-gray-900 divide-y divide-gray-200">
                {families.map(family => (
                    <FamilyItem key={family.family_id}
                        family={family}
                        updateFamily={updateFamily}
                        removeFamily={removeFamily} />
                ))}
            </dl>
        </div>
    );
}

function FamilyItem({ family, updateFamily, removeFamily }: { family: CommunityFamily, updateFamily: (f: CommunityFamily) => Promise<void>, removeFamily: (f: CommunityFamily) => Promise<void> }) {
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState({
        updating: false,
        removing: false,
    });
    const [f, setF] = useState(family);

    return (
        <div className="mb-2">
            {editing ? <div className="space-y-1  pb-3">
                <input type="text" name="name"
                    className="bg-gray-50 block border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                    placeholder="名称（必填）"
                    value={f.name}
                    onChange={(e) => {
                        setF({ ...f, name: e.target.value });
                    }} />
                <textarea name="description"
                    className="bg-gray-50 block border border-gray-300 text-gray-900 text-sm rounded w-full min-h-32 p-2.5"
                    placeholder="简介"
                    value={f.description}
                    onChange={(e) => {
                        setF({ ...f, description: e.target.value });
                    }} />
            </div> : <div>
                <div className="flex flex-col pb-3">
                    <dt className="mb-1 text-gray-500">名称</dt>
                    <dd className="text-lg font-semibold">{f.name}</dd>
                </div>
                <div className="flex flex-col py-3">
                    <dt className="mb-1 text-gray-500">简介</dt>
                    <dd className="text-lg font-semibold">{f.description}</dd>
                </div>
            </div>}
            <div className="text-center">
                {editing ? <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded-s hover:bg-green-800"
                    loading={loading.updating}
                    text='提交'
                    onClick={async () => {
                        setLoading({ ...loading, updating: true });
                        await updateFamily(f);
                        setEditing(false);
                        setLoading({ ...loading, updating: false });
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                </LoadingButton> : <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-s hover:bg-gray-800"
                    type="button"
                    onClick={() => setEditing(true)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                    修改
                </button>}
                {editing || <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 hover:bg-green-800"
                    type="button"
                    onClick={() => navigate(`${f.family_id}/users`)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                    </svg>
                    用户管理
                </button>}
                {editing ? <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-e hover:bg-gray-800"
                    type="button"
                    onClick={() => setEditing(false)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    取消
                </button> : <LoadingButton className="px-4 py-2 text-sm text-white bg-red-700 rounded-e hover:bg-red-800"
                    loading={loading.removing}
                    text='删除'
                    onClick={async () => {
                        setLoading({ ...loading, removing: true });
                        await removeFamily(f);
                        setLoading({ ...loading, removing: false });
                    }}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </LoadingButton>}
            </div>
        </div>
    );
}

export function FamilyUserList() {
    const { id, family_id } = useParams();
    const community_id = Number(id);
    const f_id = Number(family_id);
    const { warn } = useAlertContext();
    const [users, setUsers] = useState<CommunityFamilyUser[]>([]);
    const [allUsers, setAllUsers] = useState<CommunityUser[]>([]);

    const fetchFamilyUsers = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/family/${f_id}/users`);
            setUsers(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                warn(`查询失败 ${message}`);
            }
        }
    }
    const fetchCommunityUsers = async () => {
        try {
            const response = await api.get(`/api/community/${community_id}/users`);
            setAllUsers(response.data);
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                warn(`查询失败 ${message}`);
            }
        }
    }

    useEffect(() => {
        fetchFamilyUsers();
    }, [id, family_id]);
    useEffect(() => {
        fetchCommunityUsers();
    }, [id]);
    return (
        <div className="space-y-4">
            <Link className="text-white text-sm bg-black hover:bg-gray-800 rounded px-5 py-2.5 text-center"
                to="../.">
                返回
            </Link>
            <div className="grid grid-cols-4 gap-4">
                {users.map(user => (
                    <UserProfileCard key={user.user_id}
                        user={user}
                        fetchFamilyUsers={fetchFamilyUsers} />
                ))}
                {allUsers.filter(u => users.findIndex(uu => uu.user_id === u.user_id) < 0).map(user => (
                    <UserProfileCard key={user.user_id}
                        user={user}
                        fml_id={f_id}
                        fetchFamilyUsers={fetchFamilyUsers} />
                ))}
            </div>
        </div>
    );
}

export function AddFamily() {
    const { id } = useParams();
    const community_id = Number(id);
    const [family, setFamily] = useState<CommunityFamily>({
        family_id: -1,
        name: '',
        community_id: community_id,
        description: ''
    });
    const [loading, setLoading] = useState(false);
    const { success, warn } = useAlertContext();
    const navigate = useNavigate();
    const handleCreate = async () => {
        if (!family.name) {
            warn('名称必填');
            return;
        }
        setLoading(true);
        try {
            await api.post('/api/community/family', family);
            success('添加成功');
            navigate('..', { replace: true });
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                warn(`添加失败 ${message}`);
                setLoading(false);
            }
        }
    }
    return (
        <div className="space-y-4">
            <h5 className="text-xl font-medium text-gray-900">创建新家庭</h5>
            <div>
                <input type="text" name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="名称（必填）"
                    value={family.name}
                    onChange={(e) => {
                        setFamily({ ...family, name: e.target.value });
                    }}
                    required />
            </div>
            <div>
                <textarea name="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full min-h-32 p-2.5"
                    placeholder="简介"
                    value={family.description}
                    onChange={(e) => {
                        setFamily({ ...family, description: e.target.value });
                    }} />
            </div>
            <div className="text-center">
                <LoadingButton className="px-4 py-2 text-sm text-white inline-flex items-center bg-green-700 rounded-s hover:bg-green-800"
                    loading={loading}
                    text='创建'
                    onClick={async () => await handleCreate()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton>
                <button className="px-4 py-2 text-sm text-white inline-flex items-center bg-black rounded-e hover:bg-gray-800"
                    type="button"
                    onClick={() => navigate(-1)}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                    取消
                </button>
            </div>
        </div>
    );
}


function UserProfileCard({ user, fml_id, fetchFamilyUsers }:
    {
        user: CommunityFamilyUser | CommunityUser,
        fml_id?: number,
        fetchFamilyUsers: () => Promise<void>
    }) {
    const { success, error } = useAlertContext();
    const [loading, setLoading] = useState(false);

    const addUser = async () => {
        setLoading(true);
        let u = user as CommunityUser;
        try {
            await api.post('/api/community/family/user', {
                community_id: u.community_id,
                family_id: fml_id!,
                user_id: u.user_id,
                community_family_user_id: -1,
            });
            success('添加成功');
            await fetchFamilyUsers();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`添加失败 ${message}`);
                setLoading(false);
            }
        }
    };

    const removeUser = async () => {
        setLoading(true);
        try {
            await api.delete(`/api/community/family/user`, {
                data: user
            });
            success('移除成功');
            await fetchFamilyUsers();
        } catch (e) {
            console.error('Error fetching data:', e);
            if (axios.isAxiosError(e)) {
                const message = e.response && (e.response.data ? e.response.data : e.response.statusText);
                error(`移除失败 ${message}`);
                setLoading(false);
            }
        }
    };

    return (
        <div className="max-w-sm bg-white border border-gray-200 rounded">
            <div className="flex flex-col items-center pt-7 pb-10">
                <img className="w-24 h-24 mb-3 rounded-full" src={avatar} alt="Avatar" />
                <h5 className="mb-1 text-xl font-medium text-gray-900">{user.name}</h5>
                {fml_id ? <LoadingButton
                    className="text-white text-md w-2/3 bg-green-700 rounded px-4 py-2 mt-4 hover:bg-green-800"
                    loading={loading}
                    text="添加到家庭"
                    onClick={async () => await addUser()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                    </svg>
                </LoadingButton> : <LoadingButton
                    className="text-white text-md w-2/3 bg-red-700 rounded px-4 py-2 mt-4 hover:bg-red-800"
                    loading={loading}
                    text="从家庭移除"
                    onClick={async () => await removeUser()}>
                    <svg className="w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </LoadingButton>}
            </div>
        </div>
    );
}
