import React, { useState } from "react";
import AlertContext, { Alert, AlertContextType, AlertType } from './AlertContext';

const useAlert = (): AlertContextType => {
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const pushAlert = (type: AlertType, message: string) => {
        const id = Math.random();
        const alert: Alert = { id, type, message };
        setAlerts(prev => [...prev].concat([alert]));
        // setTimeout(() => {
        //     console.log(`timeout id ${id}`);
        //     setAlerts(prev => prev.filter(p => p.id !== id));
        // }, 3000);
    }

    const removeAlertById = (id: number) => {
        setAlerts(prev => prev.filter(p => p.id !== id));
    }

    const success = (message: string) => {
        pushAlert(AlertType.success, message);
    }

    const warn = (message: string) => {
        pushAlert(AlertType.warn, message);
    }

    const error = (message: string) => {
        pushAlert(AlertType.error, message);
    }

    return { alerts, success, warn, error, removeAlertById };
}

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
    const alert = useAlert();
    return (
        <AlertContext.Provider value={alert}>{children}</AlertContext.Provider>
    );
};