import React from "react";
import { Outlet } from "react-router";
import logo from "../../logo.jpg"
import { Sidebar } from "./Sidebar";
import { useEffect, useState } from "react";
import type { Community } from "../../api/community";
import api from "../../api";

export function Welcome() {
    document.title = '🥁🥁🥁';
    return (
        <div className="text-center">
            <img src={logo}
                alt="logo"
                className="mx-auto" />
        </div>
    );
}

export default function Home() {
    const [communities, setCommunities] = useState<Community[]>([]);
    const fetchCommunities = async () => {
        try {
            const response = await api.get('/api/communities');
            setCommunities(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchCommunities();
    }, []);
    return (
        <React.Fragment>
            <Sidebar communities={communities} />
            <div className="p-4 sm:ml-64">
                <Outlet context={{ communities, fetchCommunities }} />
            </div>
        </React.Fragment>
    );
}