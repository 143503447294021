import { createBrowserRouter, redirect, } from "react-router-dom";
import Index from "../pages/index/Index";
import { ErrorPage } from "../pages/error/Error";
import Home, { Welcome } from "../pages/home/Home";
import CreateCommunity from "../pages/community/CreateCommunity";
import Community from "../pages/community/Community";
import type { User } from "../api/user";
import BasicInfo from "../pages/community/BasicInfo";
import UserManagement from "../pages/community/UserManagement";
import FamilyManagement, { AddFamily, FamilyList, FamilyUserList } from "../pages/community/FamilyManagement";
import RelationManagement from "../pages/community/RelationManagement";
import PersonManagement from "../pages/community/PersonManagement";
import PersonRelationManagement from "../pages/community/PersonRelationManagement";
import api from "../api";
import NetworkGraph from "../pages/community/NetworkGraph";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Index />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/home',
        element: <Home />,
        loader: async () => {
            try {
                const response = await api.get('/api/user');
                return response.data as User;
            } catch (error) {
                console.error('Error fetching data:', error);
                return redirect('/');
            }
        },
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Welcome />
            },
            {
                path: 'createCommunity',
                element: <CreateCommunity />
            },
            {
                path: 'community/:id',
                element: <Community />,
                children: [
                    {
                        index: true,
                        element: <BasicInfo />
                    },
                    {
                        path: 'user',
                        element: <UserManagement />
                    },
                    {
                        path: 'family',
                        element: <FamilyManagement />,
                        children: [
                            {
                                index: true,
                                element: <FamilyList />
                            },
                            {
                                path: 'add',
                                element: <AddFamily />
                            },
                            {
                                path: ':family_id/users',
                                element: <FamilyUserList />
                            }
                        ]
                    },
                    {
                        path: 'relationship',
                        element: <RelationManagement />
                    },
                    {
                        path: 'person',
                        element: <PersonManagement />,
                    },
                    {
                        path: 'person-relation',
                        element: <PersonRelationManagement />
                    },
                    {
                        path: 'network-graph',
                        element: <NetworkGraph />
                    },
                ]
            },
        ]
    }
]);

export default router;